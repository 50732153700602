.jobListContainer {
  margin: 0 auto;
}

.jobListContainer .jobsTable {
  background: #FFFFFF;
}

.cellLink {
  display: block;
  padding: 12px 0px;
  text-decoration: none;
  color: #000;
}

.statusCellLink {
  display: block;
  padding: 12px 0px;
  text-decoration: none;
  white-space: pre-line;
  color: #000;
}

.filterWrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 16px 0px;
}

.textFilter {
  width: 300px;
}

.datePicker {
  width: 180px;
  font-size: 1rem;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  padding: 8px 0 5px;
  margin-left: 8px;
}

.pagination {
  margin: 16px;
  display: inline-flex;
  width: 100%;
}

.pagination ul {
  margin: auto;
}