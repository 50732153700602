.deleteModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  color: black;
  padding: 32px;
  border-radius: 8px;
}

.deleteModalButtons {
  display: flex; 
  justify-content: flex-end;
}