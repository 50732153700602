.modellingSelect {
  margin: 0 auto;
}

.modellingSelect .modellingTable {
  background: #FFFFFF;
}

.checkboxCell {
  width: 48px;
}

.subCell {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subRow {
  background: none !important;
}

.headerWrapper {
  display: flex;
}

.idWrapper {
  padding: 20px;
  margin-left: auto;
}

.headerWrapper a, .headerWrapper Button {
  align-self: center;
  margin-left: auto
}

.filterWrapper {
  display: flex;
  margin: 16px 0px;
  flex-wrap: wrap;
}

.filterWrapper .results {
  align-self: center;
  margin:0;
  margin-left: auto
}

.filterWrapper .fuelHazard {
  min-width: 250px;
  margin:0;
  margin-left: 10px;
}

.pagination {
  margin: 16px;
  display: inline-flex;
  width: 100%;
}

.pagination ul {
  margin: auto;
}

.filterHeader, .selectedHeader {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 12px;
  display: flex;
  gap: 20px;
}

.filterTitle {
  transform: translate(-4px, -15px) scale(0.75);
  position: absolute;
}

.noErrorMessage {
  display:none;
}

.errorModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  color: black;
  padding: 32px;
  border-radius: 8px;
}

button.errorModalButton {
  display: block;
  margin-left: auto;
  margin-top: 16px;
}

.textFilter {
  width: 300px;
}

.checkboxConfig {
  padding-left: 20px;
}

.checkboxConfig span {
  font-size: 10px;
}

.radioGroup {
  padding-left:10px;
}

.radioGroup span {
  font-size: 0.6rem;
}

.modellingFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #201647;
  color: white;
  justify-content: center;
  display: flex;
  gap: 20px;
}