.configuration {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 16px;
}

.checkboxConfig span {
    font-size: 0.875rem;
}

.addDate {
    display: block;
}

.simulationDate {
    margin: auto;
    padding-right: 10px;
}