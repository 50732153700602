:global(body) {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 16px;
}

/* This minimizes the impact that the react-datepicker dropdowns have on the element positions */
:global(body .react-datepicker__tab-loop) {
  width: 0;
}

:global(body .dzu-dropzone) {
  overflow: hidden;
  min-height: 48px;
  border: 1px solid #2484ff;
  background-color: white;
}

:global(body .dzu-dropzoneActive) {
  background-color: #DEEBFF;
}

:global(body .dzu-inputLabel),
:global(body .dzu-inputLabelWithFiles) {
  width: 100%;
  padding: 8px;
  margin: 0;
  background: none;
  font-size: 0.8rem;
  text-align: center;
  color: black;
}

:global(body .dzu-previewContainer) {
  flex-wrap: wrap;
  padding: 16px;
}

:global(body .dzu-previewImage) {
  display: none;
}

:global(body .dzu-previewStatusContainer),
:global(body .dzu-previewStatusContainer progress) {
  width: 100%;
}

:global(body .MuiTab-textColorInherit) {
  opacity: 1;
}

:global(body thead .MuiTableCell-root) {
  padding: 12px;
  font-weight: 700;
}

:global(body tbody .MuiTableCell-root) {
  padding: 6px 12px;
}

:global(body .MuiTableCell-root .MuiCheckbox-root) {
  padding: 0;
}

:global(body .MuiListItemText-multiline .MuiListItemText-primary) {
  word-break: break-word;
}

:global(body .MuiPaper-root .MuiTabs-flexContainer .MuiTab-textColorPrimary) {
  color: #fff;
}
