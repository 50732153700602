.modellingList {
  margin: 0 auto;
}

.modellingList .modellingTable {
  background: #FFFFFF;
}

.filterWrapper {
  display: flex;
  justify-content: space-between;
  gap:30px;
  margin: 4px 0px;
}

.textFilter {
  width: 300px;
}

.Label {
 padding: 0px 12px;
 margin: 8px;
}

.Label legend {
  padding-left: 0;
}