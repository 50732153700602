.modellingTable {
  background: #FFFFFF;
}

.modellingHeader {
  display: flex;
  justify-content: space-between;
}

.modellingHeader H1 {
  align-self: center;
  margin: 0px;
}

.fireInputs {
  display: flex;
  justify-content: left;
  gap: 20px;
}

.removePadding {
  padding: 0px;
}

.detailHeader {
  display: flex;
  justify-content: left;
  gap: 10px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding:6px 12px;
}

.status {
  flex: 1;
}

.buttons {
  display: flex;
}

.buttons a, .buttons Button {
  align-self: center;
  margin: 10px;
}

.codeBlock {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding:6px 12px;
}

.codeBlock pre {
  padding: 16px;
  margin-top: 0px;
}

.codeBlock button {
  margin-right: 10px;
}