.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  color: black;
  padding: 32px;
  border-radius: 8px;
}

.modalButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
}

button.modalButton {
  display: block;
  margin-left: auto;
  margin-top: 16px;
}

.customTitle {
  width: 480px;
}

.uploadFormContainer .uploadTabs {
  padding: 16px;
  margin-top: 16px;
  background: #ffffff;
}

.uploadFormContainer h2:first-child {
  margin-top: 0;
}

.formControls {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.configControls {
  display: flex;
  margin-top: 16px;
}

.groupId {
  padding: 10px;
  align-self: center;
  margin-left: auto;
}

.checkboxConfig {
  padding-left: 20px;
}

.checkboxConfig span {
  font-size: 10px;
}
