.addDateDates input {
  font-size: 1rem;
  background: transparent;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
}

.dzuFileName {
  margin-bottom: 8px;
}

.jobFieldList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 16px;
}

.jobFieldContainer {
  min-width: 320px;
  margin-left: 16px;
}

.jobFieldText {
  padding: 8px;
  border: 1px solid grey;
  background-color: #00b7bd;
}

.addDateText {
  margin: 8px 0;
}

.addDateWrapper {
  display: flex;
  justify-content: space-between;
}

.addDateWrapper input {
  width: 100%;
}

.addDateDates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 8px;
}

.addDateDates > div {
  width: 48%;
  margin-top: 8px;
}

.addDateDates > div:first-child {
  width: 100%;
  margin: 0;
}

.jobFieldContainer:first-child {
  margin: 0;
}

.jobFieldContainer h3,
.jobFieldContainer p {
  margin: 0 8px;
}

.jobFieldItemList {
  height: 280px;
  overflow-y: auto;
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

.jobFieldItemListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  padding: 0 8px;
  word-break: break-all;
  background: white;
  border-radius: 4px;
}

.jobFieldItemListItemText {
  margin: 0;
  width: 100%;
  padding: 4px 8px;
}

button.smallButton {
  padding: 3px 6px 8px;
  min-width: auto;
  font-size: 2rem;
  line-height: 1rem;
}

button.smallButton:disabled {
  color: grey;
}

.timeEntryList {
  list-style: none;
  padding: 0;
  font-size: 0.75rem;
}

button.downloadLinkButton {
  margin: 8px 0 4px;
}

.assetDownloader {
  display: flex;
  align-items: baseline;
  margin: 4px;
}

.assetDownloader a {
  color: black;
  font-size: 1.2rem;
}

.assetDownloader button {
  margin: 8px 16px 16px 0;
}
