.configuration {
    margin-left: 10px;
    position: relative;
    display: flex;
}
  
.configurationFieldSet {
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
}
  
.configurationLegend {
    color: rgba(0, 0, 0, 0.6);
    position: relative;
    top: -1px;
    padding: 0 5px;
    height: 11px;
    font-size: 0.75em;
}