.jobsTable {
  background: #FFFFFF;
}

.cellLink {
  display: block;
  padding: 12px 0px;
  white-space: pre-line;
}

.cellFlex {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 4px 0;
}

.cellErrors {
  display: block;
  padding: 4px 0;
}

.subTitle {
  transform: translate(-10px, -15px) scale(0.85);
  position: absolute;
  font-weight: bold;
  background: white;
  padding: 0px 2px;
}

.jobHeader {
  display: flex;
}

.jobHeader H1 {
  align-self: center;
  margin: 0px;
}

.detailHeader {
  display: flex;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding:6px 12px;
}

.settings {
  display: flex;
  margin-bottom: 8px;
}

.ids {
  display: flex;
  margin-left:auto;
  gap: 20px;
  align-self: center;
  height: 55px;
}

.ids div {
  align-self: center;
}

.checkboxConfig {
  padding-left: 20px;
}

.checkboxConfig span {
  font-size: 0.875rem;
  padding: 0px;
}

.buttons {
  display: flex;
  margin-left: auto
}

.buttons a, .buttons Button {
  align-self: center;
  margin: 10px;
}

.uploadTabs {
  padding: 16px;
  margin-top: 16px;
  background: #FFFFFF;
}

.uploadTabs h3:first-child {
  margin-top: 0;
}